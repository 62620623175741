.container {
  background: var(--content-bk);
  color: var(--content-primary);

  border-radius: 8px;
  padding: 32px 24px;
  padding: 48px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 600px;
  min-width: 200px;
  margin: 0 auto;
  width: 100%;

  gap: 32px;
  //flex: 1;

  form {
    width: 100%;
    padding: 0px 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    input, button {
      width: 100%;
    }
  }
  
  nav {
    margin-top: 24px;
    display: flex;
    gap: 32px;    
  }
}
