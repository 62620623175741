* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --page-bk: #000;
  --page-primary: #fff;

  --btn-bk: #0c3569;
  --btn-bk-hover: #297ee7;
  --btn-primary: #fff;

  --input-border: #ccc;
  --content-bk: #fff;
  --content-primary: #000;

  --nav-btn-bk-dark: #253676;
  --nav-btn-bk-light: #6d83ef;
  --nav-btn-primary: #fff;
}

body, input, textarea, button {
  font: 400 1rem Cabin, sans-serif;
}

button {
  cursor: pointer;
  border: 0;
  padding: 12px;
  border-radius: 6px;
  color: var(--btn-primary);
  background: var(--btn-bk);
  transition: 0.2s;

  &:hover {
    background: var(--btn-bk-hover);
  }
}

a {
  font-family: Cabin, sans-serif;
  font-size: 16pt;

  color: var(--btn-bk);
  text-decoration: none;

  &:hover {
    color: var(--btn-bk-hover);
    text-decoration: underline;
  }
}

input {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid var(--input-border);
}

body {
  background: var(--page-bk);
  color: var(--page-primary); 
  display: flex;
  flex-direction: column;
  //align-items: stretch;
  justify-content: center;
  width: 100%;
  flex: 1;
  height: 100vh;
  padding: 24px 8px;
}

