.container {
  background: var(--content-bk);
  color: var(--content-primary);
  
  border-radius: 8px;
  padding: 48px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  max-width: 600px;
  //min-width: 100px;
  //margin: 0 auto;
  //width: 100%;
  
  //gap: 32px;
  flex: 1;

  form {
    //width: 100%;
    //background: red;
    //flex: 1;

    //padding: 0px 48px;
    //padding: 0;

    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //gap: 20px;
    min-width: 580px;

    input, button {
      //width: 100%;
    }
  }
  
  nav {
    margin-top: 24px;
    display: flex;
    gap: 32px;    
  }
}
