.my-progress-bar {
  background: #000;
  border-radius: 4px;
  
  .progress {
    background: var(--btn-bk-hover);
    transition: 0.2s;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      color: var(--btn-primary);
      padding: 4px;
    }
  }
}